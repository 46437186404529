import React from 'react'
import PropTypes from 'prop-types'
import * as sectionContainerStyling from './sectionContainer.module.scss'
import classNames from 'classnames'

const SectionContainer = ({
    title,
    subtitle,
    introComponent,
    children,
    asColumn = false,
}) => {
    const sectionContainerContentWrapperClasses = classNames({
        [sectionContainerStyling.contentWrapper]: true,
        [sectionContainerStyling.contentWrapperAsColumn]: asColumn,
    })

    return (
        <section>
            <div className={sectionContainerStyling.introWrapper}>
                <h2 className={sectionContainerStyling.heading} id={title}>
                    {title}
                </h2>
                {subtitle && (
                    <p className={sectionContainerStyling.subtitle}>
                        {subtitle}
                    </p>
                )}
                {introComponent && <>{introComponent}</>}
            </div>
            <div className={sectionContainerContentWrapperClasses}>
                {children}
            </div>
        </section>
    )
}

SectionContainer.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    introComponent: PropTypes.node,
    children: PropTypes.node.isRequired,
    asColumn: PropTypes.bool,
}

export default SectionContainer

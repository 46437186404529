import React from 'react'
import Cta from './common/cta'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { preventWidow } from '../utils/helpers'
import * as heroStyles from './hero.module.scss'

const Hero = ({
    desktopImage,
    mobileImage,
    fineprint,
    children,
    heading,
    subheading,
    body,
    ctaText,
    ctaLink,
}) => {
    let backgroundImages = null
    if (desktopImage && mobileImage) {
        backgroundImages = withArtDirection(getImage(mobileImage), [
            {
                media: `(min-width: 48em)`,
                image: getImage(desktopImage),
            },
        ])
    }

    return (
        <section className={heroStyles.bannerWrapper}>
            <div className={heroStyles.contentWrapper}>
                <div className={heroStyles.innerContentWrapper}>
                    <h1 className="biggest">{preventWidow(heading)}</h1>
                    {subheading && <h2>{preventWidow(subheading)}</h2>}
                    <p
                        className={heroStyles.body}
                        dangerouslySetInnerHTML={{
                            __html: preventWidow(body),
                        }}
                    />
                    {children && children}
                    {backgroundImages && (
                        <GatsbyImage
                            className={heroStyles.image}
                            image={backgroundImages}
                            alt={heading}
                        />
                    )}
                    {ctaText && ctaLink && (
                        <Cta
                            text={ctaText}
                            isLink={true}
                            to={ctaLink}
                            isInverted={true}
                        />
                    )}
                </div>
            </div>
            <div className={heroStyles.waveWrapper}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 150">
                    <path
                        fill="#1B4942"
                        fillOpacity="1"
                        d="M0,128L80,117.3C160,107,320,85,480,96C640,107,800,149,960,138.7C1120,128,1280,64,1360,32L1440,0L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                    ></path>
                </svg>
            </div>
        </section>
    )
}

Hero.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    fineprint: PropTypes.string,
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string,
    body: PropTypes.string.isRequired,
    ctaText: PropTypes.string,
    ctaLink: PropTypes.string,
}

export default Hero

import React from 'react'
import PropTypes from 'prop-types'
import * as searchBoxStyles from './searchBox.module.scss'
import classNames from 'classnames'

const SearchBox = ({ placeholder, onChange, inverted }) => {
    const inputClasses = classNames({
        inverted: inverted,
    })

    return (
        <div className={searchBoxStyles.searchBox}>
            <input
                className={inputClasses}
                type="text"
                aria-label="Search"
                placeholder={placeholder}
                onChange={onChange}
            />
        </div>
    )
}

SearchBox.propTypes = {
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    inverted: PropTypes.bool,
}

export default SearchBox

import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Hero from '../components/hero'
import SectionContainer from '../components/layout/sectionContainer'
import SearchBox from '../components/common/searchBox'
import * as vinerStyling from '../pages/viner.module.scss'
import * as pageStyling from '../pages/pageStyling.module.scss'

const WineCountryPage = ({ data, pageContext, location }) => {
    const country = pageContext.country

    const [query, setQuery] = useState(null)

    const handleInputChange = (event) => {
        setQuery(event.target.value)
    }

    const doesWineMatchQuery = (wine) => {
        const currentQuery = query.toLowerCase()

        return (
            wine.title.toLowerCase().includes(currentQuery) ||
            (wine.region && wine.region.toLowerCase().includes(currentQuery)) ||
            wine.country.toLowerCase().includes(currentQuery) ||
            (wine.grapes &&
                wine.grapes.some((grape) =>
                    grape.toLowerCase().includes(currentQuery)
                )) ||
            wine.wineType.toLowerCase().includes(currentQuery)
        )
    }

    const wines = data.allContentfulWine.edges.reduce(
        (listOfWines, { node: wine }) => {
            if (query === null || doesWineMatchQuery(wine)) {
                // Get first letter of name of current element
                const group = wine.producer[0]
                // If there is no property in accumulator with this letter create it
                if (!listOfWines[group])
                    listOfWines[group] = { group, children: [wine] }
                // If there is push current element to children array for that letter
                else listOfWines[group].children.push(wine)
                // Return accumulator
            }
            return listOfWines
        },
        {}
    )

    const renderWinesInGroup = (wines) => {
        return wines.map((wine) => (
            <Link key={wine.id} className={vinerStyling.wine} to={wine.slug}>
                {wine.title}
            </Link>
        ))
    }

    const allWines = Object.values(wines)

    allWines.sort((a, b) => a.group.localeCompare(b.group))

    const title = `Viner från ${country}`
    const description = `Nyfiken på viner från ${country}? Här hittar du alla viner från ${country} som finns på Vinologik och kan läsa mer om var och en!`

    return (
        <>
            <GatsbySeo title={title} description={description} />
            <Hero heading={title} body={description} />
            <div className={pageStyling.defaultWrapper}>
                <SectionContainer
                    title={`Alla viner från ${country}`}
                    subtitle="Välj vin nedan för att läsa mer om det, är du inloggad kan du även kommentera och berätta vad du själv tycker."
                    asColumn={true}
                >
                    <SearchBox
                        placeholder={'Sök efter ett vin...'}
                        onChange={handleInputChange}
                        inverted={true}
                    />
                    {!allWines.length && (
                        <div>
                            <h4>
                                Hittade tyvärr inget, testa igen med ett annat
                                sökord.
                            </h4>
                        </div>
                    )}
                    {allWines.map((wine) => (
                        <div
                            className={vinerStyling.groupWrapper}
                            key={wine.group}
                        >
                            <h1 className={vinerStyling.groupHeadline}>
                                {wine.group}
                            </h1>
                            {renderWinesInGroup(wine.children)}
                        </div>
                    ))}
                </SectionContainer>
            </div>
        </>
    )
}

export default WineCountryPage

export const pageQuery = graphql`
    query ($country: String!) {
        allContentfulWine(filter: { country: { eq: $country } }) {
            edges {
                node {
                    id
                    title
                    wineType
                    region
                    country
                    grapes
                    producer
                    name
                    vintage
                    slug
                }
            }
        }
    }
`
